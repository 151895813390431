
.head{
    
    margin: 0;
    padding: 0;
    width: 100%;
    background-color:rgba(138, 176, 24, 0.21);
    height: 9%;
    display: inline-block;
    position:absolute;
    border-bottom: solid 1px rgba(255, 255, 255, 0.845);
   
 
    .rotating-logo {
       
        width: 15%;
        display: inline;
       // animation: rotate 3s linear infinite;
        position: relative;
        top: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
    /*  @keyframes rotate {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }*/
      

      .heading{
        color:darkblue;
        text-shadow: 1px 1px 2px #434ac7;
        display:inline-block;
        position: absolute;
        top: -16px;
       cursor: pointer;
    }


    .heading:hover{
        transition: 0.5s;
        color:darkred;
        text-shadow: 1px 1px 2px #4abe1d;
    }

    select{
        margin: 4px;
        color: red;
        background-color: transparent;
        border: solid 1px;
    }

    ul{
        margin: 0;
        padding: 0;
        display:inline;
        margin-left: 3%;
        margin-right: 3%;
    }

    li{
        display: inline;
        padding: 6px;
        background-color: rgba(0,0,0,0.3);
        color: aqua;
        font-size: 30px;
        margin-right: 15px;    
        cursor: pointer;  
        border: solid 1px transparent;
    }

    li:hover{
        color:red;
        transition: 0.7s;
        border: solid 1px red;
        border-radius: 20%;
      }

}

.mg{
    img{
        width: 100%;
    }
}

