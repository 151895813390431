
body{
  background-color: rgba(0,0,0,0.3);
}
  
#background-video{

  height: 100%;
  width: 100%;
  top: 0;
  padding: none;
  position: fixed;
  object-fit: cover;
  }

  .app{
   
  height: 100%;
  width: 100%;
  top: 0;
  padding: none;
  object-fit: cover;
  }


.bt{
  width: 1000px;
  height: 100px;
  background-color: antiquewhite;
}