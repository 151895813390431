


.buttons-container {
    position: sticky;
    display: flex;
    justify-content: center;
    display: block;
  }

  .button {
    position: relative;
    top: 100px;
    border: 1px solid #e5f1087b;
    background: transparent;
    margin: 5px 5px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #f1e9e9;
    }
  

  .button:hover {
    box-shadow: 3px 3px 0px 0px rgba(168, 252, 0, 0.595);
  }


.items-container {
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    color: #f11616;
    
  }


.item{
    display: inline-block;
    width:500px ;
    height: 370px;
    border: solid;
   margin: 10px 10px;
   padding: 15px 15px;
   margin-right: 4px;
   margin-bottom: 0;
   position:sticky;
  color: aliceblue;
  background-color:rgba(51, 51, 48, 0.775);
    box-shadow: 2px 2px rgba(255, 166, 0, 0.197);
    border-color: rgba(76, 73, 73, 0.395);
    h1{
        text-decoration: underline;
       
      }
      a{
        color: white;
      }
      .know-more{
        color: #e0880dd2;
        font-size:larger;
        text-decoration: none;
        
      }
}


.item:hover{
    transition: 0.5s;
    background-color:rgba(251, 251, 251, 0.316);
    box-shadow: 5px 5px rgba(235, 232, 232, 0.378);
    border-color: rgba(255, 228, 196, 0.152);
}

.category {
    height: 23px;
    width: 280px;
    font-size: 15px;
    background-color: black;
    border-radius: 10px;
    text-align: center;
    color: white;
    position: relative;
   
  }
  

.active {
    background-color: #e0880d;
    color: #fff;

   
  }