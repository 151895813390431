
.footer{
    margin-top: 15px;
    border-top: solid 1px;
    border-color: rgba(243, 242, 242, 0.605);
    text-align: center;
    position:relative;
    color: bisque;
    width: 100%;
  

    .icon{
        margin-top: 10px;
    }
    
    li{
        display: inline;
    margin-right: 15px;
    cursor: pointer;
   
 
    }

    li:hover{
        color:red;
        transition: 0.3s;
      }

    a{
        color: rgb(212, 203, 203);
    }

    
}